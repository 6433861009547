import React, { forwardRef, ReactElement, ReactNode } from 'react';
import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';

import HeaderProps from '@/src/components/UI/HeaderType';
import { src } from '@/src/components/UI/HeroImageProps';
import HeroImage from '@/src/components/UI/HeroImage';
import dynamic from 'next/dynamic';
import { APP_CONTENT_ID } from '@/lib/constant';
import { isNotSet } from '@/utils/formatChecker';

const Header = dynamic(() => import('@/src/components/UI/Header'), {
  ssr: false,
});

interface FrameProps extends BoxProps {
  children: ReactNode;
}

const Frame = forwardRef<HTMLDivElement, FrameProps>(({ children, ...props }, ref) => (
  <Box w='100%' maxW={1440} mx='auto' {...props} ref={ref}>
    {children}
  </Box>
));

interface Props extends HeaderProps {
  children: ReactNode;
  // headerType?: HeaderType;
  hasHeaderPlaceHolder?: boolean;
  src?: src;
  text?: string;
  hasPadding?: boolean;
  hasBottomMargin?: boolean;
  action?: ReactElement;
  frameProps?: BoxProps;
  mobileFlexProps?: FlexProps;
  appContentProps?: FlexProps;
  bannerSrc?: string;
}

const FullPageImpl = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      src,
      text,
      sid,
      title,
      hasHeaderPlaceHolder,
      hasBottomMargin = true,
      headerRight,
      hasPadding,
      action,
      frameProps = {},
      appContentProps,
      keepNavTextColor,
      heroWithMask,
      shareSocialInfo,
      keepHeaderMode,
      bannerSrc,
      isNotSubPage,
    },
    ref
  ) => {
    return (
      <React.Fragment>
        <Flex
          id={APP_CONTENT_ID}
          position='relative'
          flex={1}
          justify={{ base: 'flex-start', sm: 'stretch' }}
          direction='column'
          {...appContentProps}
        >
          {hasHeaderPlaceHolder ? <Flex w={'100%'} h={{ base: '60px', sm: '64px' }} /> : null}
          {(src ?? bannerSrc) && (
            <HeroImage
              mask={heroWithMask}
              text={text}
              src={src}
              alt={`${src}_hero_img`}
              action={action}
              bannerSrc={bannerSrc}
            />
          )}
          <Frame
            ref={ref}
            id={'main-container'}
            mb={hasBottomMargin ? { base: 88, sm: 14 } : undefined}
            px={hasPadding ? { sm: 4, md: 8 } : 0}
            {...frameProps}
          >
            {children}
          </Frame>
        </Flex>
        <Header
          keepHeaderMode={keepHeaderMode ?? isNotSet(src)}
          sid={sid}
          headerRight={headerRight}
          title={title}
          keepNavTextColor={keepNavTextColor}
          shareSocialInfo={shareSocialInfo}
          isNotSubPage={isNotSubPage}
        />
      </React.Fragment>
    );
  }
);

export default FullPageImpl;
