import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link, { LinkProps } from 'next/link';
import { Button, Flex, useMediaQuery } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';

import OpenGraph from '@/components/OpenGraph';
import Listings from '@/src/components/UI/Listings';
import FullPage from '@/src/components/UI/FullPage';
import useT from '@/lib/t';
import { CountryEnum, JobEntity, ListingDetail, StaticOptionEntity, UserStoryShowcaseEntity } from '@/swagger';
import { NextPage } from 'next';
import classNames from 'classnames';
import Image from 'next/image';
import { QA_DATA, QAItem } from '@/pages/abroad';
import { RiDoubleQuotesL } from 'react-icons/ri';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import useIsTopOrEndReached from '@/src/hooks/useIsTopOrEndReached';
import Flexbox from '@/components/common/Flexbox';
import { isEmptyArray } from '@/utils/formatChecker';

interface Props {
  listings: Array<ListingDetail>;
  jobs: Array<JobEntity>;
  conferenceOptions: Array<StaticOptionEntity>;
  userStories: Array<UserStoryShowcaseEntity>;
  banner: StaticOptionEntity;
}

const IMAGE_ASSETS = {
  abroad: 'https://cdn.funwoo.com.tw/assets/page/homepage/abroad.webp',
  userStoryG: 'https://cdn.funwoo.com.tw/assets/page/homepage/user_story_2024_miss_yen.jpg',
  userStoryF: 'https://cdn.funwoo.com.tw/assets/page/homepage/user_story_2023_irvine_mr_kong.jpg',
  userStoryA: 'https://cdn.funwoo.com.tw/assets/page/homepage/user_story_1_rect.webp',
  userStoryB: 'https://cdn.funwoo.com.tw/assets/page/homepage/user_story_2_rect.webp',
  userStoryC: 'https://cdn.funwoo.com.tw/assets/page/homepage/user_story_3_square.webp',
  userStoryD: 'https://cdn.funwoo.com.tw/assets/page/homepage/user_story_2020_bellevue.webp',
  userStoryE: 'https://cdn.funwoo.com.tw/assets/page/homepage/user_story_2023_irvine.webp',
  sellerServices: 'https://cdn.funwoo.com.tw/assets/page/homepage/seller_services.webp',
};
const Home: NextPage<Props> = ({ listings, conferenceOptions, userStories, banner }) => {
  const sellerServices = useRef(['擬定合理市價有效達到銷售共識', '提升房屋吸引力', '精準找到對的買家']);

  return (
    <FullPage hasPadding={false} src='Home' action={<PageAction />} hasBottomMargin={false} bannerSrc={banner.value}>
      <OpenGraph imageUrl={banner.value} />
      <section className={'pt-4 pb-16 px-4'}>
        {isEmptyArray(conferenceOptions) ? (
          <div className={'mb-8'}>
            <Heading>房屋精選</Heading>
            <p className={'pb-4 text-center text-gray700'}>
              FUNWOO 將依照客戶購屋需求，媒合市場上最佳建案。
              <br />
              <Link href={'/abroad#contact-us'} passHref>
                <a className={'underline'}>點選此填寫美國置產購屋需求</a>
              </Link>
            </p>
          </div>
        ) : (
          <div id={'conferences'} className={'-mt-15 sm:-mt-16'}>
            <div className={'pt-15 sm:pt-16'}>
              <Heading>主題講座</Heading>
              <div className={'p-6 space-y-4 md:space-y-0'}>
                {conferenceOptions.map((option) => (
                  <ConferenceCard key={option.id} {...option} />
                ))}
              </div>
              <Flexbox align={'center'} justify={'center'} className={'mb-8'}>
                <Link href={'/abroad#contact-us'} passHref>
                  <Button as={'a'} fontWeight={500} id={'visit_lecture_registration_from_homepage'}>
                    講座報名
                  </Button>
                </Link>
              </Flexbox>
            </div>
          </div>
        )}
        <Listings data={listings} />
        <Flex
          py={{ base: 8 }}
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ md: 'center' }}
          alignItems={{ sm: 'center' }}
        >
          <Link
            href={{
              pathname: '/buy',
              query: { country: CountryEnum.TW },
            }}
            passHref
          >
            <Button
              as={'a'}
              w={{ base: 'full', sm: '425px', lg: '163px' }}
              variant={'outline'}
              borderRadius={0}
              fontWeight={500}
              className={'mb-4 md:mb-0 md:mr-4'}
            >
              查看國內房源
            </Button>
          </Link>
          <Link
            href={{
              pathname: '/buy',
              query: { country: CountryEnum.US },
            }}
            passHref
          >
            <Button as={'a'} w={{ base: 'full', sm: '425px', lg: '163px' }} fontWeight={500}>
              查看美國房源
            </Button>
          </Link>
        </Flex>
      </section>
      <Abroad />
      <UserStory stories={userStories} />
      <div className={'flex flex-col md:flex-row sm:items-center py-16 px-4 md:px-8'}>
        <div
          className={classNames(
            'relative mb-6 md:mb-0 md:flex-1 sm:w-full md:w-auto sm:max-w-[29.25rem] md:max-w-none'
          )}
        >
          <Image src={IMAGE_ASSETS.sellerServices} width={3} height={2} layout={'responsive'} />
        </div>
        <div className={'hidden md:block md:w-8'} />
        <div className={'md:flex-1'}>
          <div className={'md:px-8'}>
            <h2 className={classNames('mb-4', 'text-2xl leading-[3rem] text-gray900 font-medium')}>全方面賣屋策略</h2>
            <p className={classNames('mb-4', 'leading-6 text-gray700')}>
              結合最新科技和創新行銷，精準推廣你的房屋，創造最大價值。
            </p>
            <div className={classNames('mb-4')}>
              {sellerServices.current.map((service) => (
                <div key={service} className={'flex items-center text-lg leading-8 font-medium'}>
                  <div className={'flex items-center justify-center mr-2'}>
                    <span className={'block w-1 h-1 bg-gray900 rounded-[999px]'} />
                  </div>
                  <span>{service}</span>
                </div>
              ))}
            </div>
            <div className={'py-4'}>
              <Link href={'/sell'} passHref>
                <Button
                  as={'a'}
                  display={{ base: 'flex' }}
                  width={{ base: 196 }}
                  mx={{ base: 'auto', md: '0' }}
                  fontWeight={500}
                  className={'items-center justify-center'}
                >
                  瞭解賣屋策略
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/*<div className={'sm:flex sm:items-center sm:justify-center p-4 pb-[8rem] sm:pb-16 bg-gray50'}>*/}
      {/*  <div className={'sm:w-full sm:max-w-[29.25rem] md:max-w-[56.625rem]'}>*/}
      {/*    <Heading>加入 FUNWOO</Heading>*/}
      {/*    <p className={classNames('mb-8', 'leading-6 text-gray700 text-center')}>正在招募專業熱誠的你</p>*/}
      {/*    {jobs.map(({ id, sid, title }) => (*/}
      {/*      <Link key={id} href={`/jobs/${sid}`} passHref>*/}
      {/*        <a className={'border border-gray900 py-4 justify-between flex flex-row items-center px-3 w-full mb-4'}>*/}
      {/*          <p className=' leading-normal tracking-wide font-medium text-dark-grey uppercase'>{title}</p>*/}
      {/*          <Image*/}
      {/*            src={'/arrow-right.svg'}*/}
      {/*            alt={`click icon to read more about job ${title}`}*/}
      {/*            width={24}*/}
      {/*            height={24}*/}
      {/*          />*/}
      {/*        </a>*/}
      {/*      </Link>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </FullPage>
  );
};
export default Home;
const PageAction: React.FC = () => {
  const t = useT('pages.index');
  return (
    <Flex align={'center'} justify={'center'} position={'absolute'} className={'top-0 left-0 w-full h-full z-20'}>
      <Flex alignItems={'center'} flexDirection={'column'}>
        <p
          className={classNames(
            'text-[24px] leading-[42px] sm:text-[24px] sm:leading-[3rem] md:text-[24px]',
            'text-center font-medium uppercase',
            'text-white mb-4 text-shadow'
          )}
        >
          {t('slogan')}
        </p>
        <Flex flexDirection={{ base: 'column', md: 'row' }} className={''}>
          <PageActionButton href={'/abroad'}>美國置產</PageActionButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
const PageActionButton: React.FC<{ href: LinkProps['href'] }> = ({ children, href }) => {
  return (
    <Link href={href} passHref>
      <Button
        as={'a'}
        border={0}
        variant='secondary'
        width={{ base: 192 }}
        className={'mb-4 last:mb-0 md:mb-0 md:mr-4 md:last:mr-0'}
        fontWeight={500}
      >
        {children}
      </Button>
    </Link>
  );
};
const Heading: React.FC<{ theme?: 'white' | 'black'; className?: string }> = ({
  children,
  theme = 'white',
  className,
}) => {
  return (
    <p
      className={classNames(className, 'py-2 text-2xl leading-[2rem] text-center font-medium', {
        'text-white': theme === 'black',
        'text-black': theme === 'white',
      })}
    >
      {children}
    </p>
  );
};

const ACCEPTABLE_CONFERENCE_CITY = ['taipei', 'taichung', 'kaohsiung', 'hsinchu', 'tainan', 'taoyuan'] as const;
const ACCEPTABLE_CONFERENCE_CITY_LABEL: Record<typeof ACCEPTABLE_CONFERENCE_CITY[number], string> = {
  taipei: '台北',
  taichung: '台中',
  kaohsiung: '高雄',
  hsinchu: '新竹',
  tainan: '台南',
  taoyuan: '桃園',
};
const ACCEPTABLE_CONFERENCE_FORMAT = new RegExp(
  `^(${ACCEPTABLE_CONFERENCE_CITY.join('|')})_(\\d{8})_(\\d{4})(?:_(\\d{4}))?$`
);
const ConferenceCard: React.FC<StaticOptionEntity> = ({ value, active, description }) => {
  const isAcceptable = useMemo(() => ACCEPTABLE_CONFERENCE_FORMAT.test(value), [value]);

  const serializeDate = useCallback((date: string, time: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6).replace(/^0+/g, '') || '0';
    const day = date.slice(6, 8).replace(/^0+/g, '') || '0';
    const hour = time.slice(0, 2).replace(/^0+/g, '') || '0';
    const minute = time.slice(2, 4);

    const dateIsoString = `${year}/${month}/${day} ${hour}:${minute}:00`;
    return new Date(dateIsoString);
  }, []);
  const date = useMemo(() => {
    const [_, __ = '', date = '', time = ''] = ACCEPTABLE_CONFERENCE_FORMAT.exec(value) ?? [];
    return serializeDate(date, time);
  }, [value]);

  const serializedValue = useMemo(() => {
    const [_, city = '', _date, _time, endTime] = ACCEPTABLE_CONFERENCE_FORMAT.exec(value) ?? [];

    const weekday = date.toLocaleDateString('zh-TW', { weekday: 'long' });
    const timeDisplay = date
      .toLocaleTimeString('zh-TW', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(/(上午|下午)/, '$1 ');
    const endData = endTime ? serializeDate(_date, endTime) : new Date(date.getTime() + 60 * 60 * 1000);
    const endTimeDisplay = endData
      .toLocaleTimeString('zh-TW', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(/(上午|下午)/, '$1 ');

    return {
      city: ACCEPTABLE_CONFERENCE_CITY_LABEL[city as typeof ACCEPTABLE_CONFERENCE_CITY[number]],
      date: `${date.getFullYear()} 年 ${date.getMonth() + 1} 月 ${date.getDate()} 日 ${weekday}`,
      time: `${timeDisplay} - ${endTimeDisplay}`,
    };
  }, [value]);

  if (!active || !isAcceptable) return null;
  // If date is not valid, return null
  if (isNaN(date.getTime())) return null;

  return (
    <Flexbox
      id={value}
      direction={'column'}
      className={'md:p-4 md:flex-row space-y-2 md:space-y-0 font-noto'}
      align={'center'}
    >
      <p className={'flex-1 text-lg font-bold text-center md:text-left'}>{serializedValue.city}</p>
      <p className={'flex-[2] text-sm md:text-lg md:font-bold text-center md:text-left'}>{serializedValue.date}</p>
      <p className={'flex-[2] text-sm md:text-lg md:font-bold text-center md:text-left'}>{serializedValue.time}</p>
      <p className={'flex-[2] text-xs md:text-lg text-gray700 md:font-bold text-center md:text-left'}>{description}</p>
    </Flexbox>
  );
};

const Abroad: React.FC = () => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [openCardIndex, setOpenCardIndex] = useState<number | null>(null);

  const dataContainerRef = useRef<HTMLDivElement | null>(null);

  const { scrollPositionChecker, onContainerScroll, topEndReached, bottomEndReached } = useIsTopOrEndReached({
    container: dataContainerRef.current,
  });

  const data = useMemo(() => (showMore ? QA_DATA : QA_DATA.slice(0, 3)), [showMore]);

  const triggerShowMore = useCallback(() => setShowMore((prev) => !prev), []);

  const onDataItemCollapse = useCallback(() => {
    scrollPositionChecker();
  }, []);

  const onCardClick = useCallback((cardIndex: number) => {
    setOpenCardIndex((prev) => (prev === cardIndex ? null : cardIndex));
  }, []);

  useEffect(() => {
    if (!dataContainerRef.current) return;
    onDataItemCollapse();
  }, [data]);

  return (
    <div
      className={classNames('flex flex-col md:flex-row py-16 px-4 md:p-16 md:px-8 md:max-h-[36rem] bg-gray50', {
        'sm:items-stretch': showMore,
        'sm:items-center': !showMore,
      })}
    >
      <div className={'md:flex-1 sm:w-full md:w-auto sm:max-w-[29.25rem] md:max-w-none md:flex-shrink-0'}>
        <div className={classNames('mb-6 md:mb-0 md:pr-4')}>
          <Image src={IMAGE_ASSETS.abroad} width={3} height={2} layout={'responsive'} />
        </div>
      </div>
      <div className={'md:flex-1 sm:max-w-[29.25rem] md:max-w-none md:flex-shrink-0'}>
        <div className={'md:flex md:flex-col md:pl-4 md:pr-8 md:max-h-[28rem] md:overflow-hidden'}>
          <h2 className={classNames('mb-4', 'text-2xl leading-[3rem] text-gray900 font-medium md:flex-shrink-0')}>
            美國置產服務
          </h2>
          <p className={classNames('mb-4', 'leading-6 text-gray700 md:flex-shrink-0')}>
            服務據點涵蓋美國六大城市，由當地執業房產顧問為您解答所有海外置產問題，量身打造一條龍服務。
          </p>
          <div className={'relative mb-4 md:flex md:overflow-hidden md:flex-1'}>
            <div
              ref={dataContainerRef}
              onScroll={onContainerScroll}
              className={classNames('md:flex-1 md:overflow-scroll')}
            >
              {data.map(({ question, answer, subItems }, index) => (
                <QAItem
                  onCollapseEnd={onDataItemCollapse}
                  key={question}
                  question={question}
                  answer={answer}
                  subItems={subItems}
                  onCardClick={() => onCardClick(index)}
                  open={openCardIndex === index}
                />
              ))}
            </div>
            <div
              className={classNames('hidden absolute top-0 left-0 w-full h-12', { 'md:block': !topEndReached })}
              style={{
                backgroundImage: 'linear-gradient(to bottom, rgb(250, 250, 250) 0%, rgba(250, 250, 250, 0.27) 75%)',
              }}
            />
            <div
              className={classNames('hidden absolute bottom-0 left-0 w-full h-12', {
                'md:block': !bottomEndReached,
              })}
              style={{
                backgroundImage: 'linear-gradient(to top, rgb(250, 250, 250) 0%, rgba(250, 250, 250, 0.27) 75%)',
              }}
            />
          </div>
          <Button
            onClick={triggerShowMore}
            display={{ base: 'block' }}
            width={{ base: 196 }}
            mx={{ base: 'auto', md: '0' }}
            className={'md:flex-shrink-0'}
          >
            {showMore ? '隱藏' : '查看'}
            更多常見問答
          </Button>
        </div>
      </div>
    </div>
  );
};

export const UserStory: React.FC<{
  stories: Array<UserStoryShowcaseEntity>;
}> = ({ stories }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [match] = useMediaQuery('(min-width: 1024px)');

  return (
    <div
      id={'user-case'}
      // className={'scroll-pt-15 sm:scroll-pt-16'}
      className={'-mt-15 sm:-mt-16'}
    >
      <div className={'pt-15 sm:pt-16'}>
        <div className={'py-8 bg-black text-white'}>
          <Heading theme={'black'}>專業房產顧問團隊</Heading>
          <p className={'text-center'}>打造賓至如歸的交易體驗</p>
          <div className={'md:mb-2.5 py-6 md:py-16 px-4 sm:px-8 md:px-0'}>
            <div
              className={classNames('sm:relative', 'pt-16 md:pt-0', 'border border-solid border-white md:border-none')}
            >
              <Swiper
                onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                className={'w-full md:px-8'}
                slidesPerView={match ? 1.5 : 1}
                onSwiper={setSwiper}
                spaceBetween={match ? 64 : 0}
              >
                {stories.map(({ id, buyer, content, photoUrl }) => (
                  <SwiperSlide
                    key={id}
                    className={classNames('px-4 sm:px-16 md:px-0', 'md:border md:border-solid md:border-white')}
                    style={{ height: 'auto' }}
                  >
                    <div className={'md:flex md:items-end md:p-16'}>
                      <div className={'flex items-center justify-center md:mr-8'}>
                        <div className={classNames('relative', 'w-[15.5rem] sm:w-[23.25rem] md:w-[15.5rem]')}>
                          <div className={'w-full pt-[100%] sm:pt-[calc(100%*2/3)] md:pt-[100%]'} />
                          <Image src={photoUrl} layout={'fill'} objectFit={'cover'} />
                        </div>
                      </div>
                      <div className={'md:relative'}>
                        <div
                          className={
                            'md:absolute md:top-0 md:transform md:-translate-y-full flex items-center justify-center md:justify-start'
                          }
                        >
                          <RiDoubleQuotesL size={80} />
                        </div>
                        <p className={'mb-4 text-xl leading-[1.6]'}>{content}</p>
                        <p className={'mb-8 md:mb-0 leading-6'}>{buyer}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className={'flex border-t sm:border-none border-solid border-white divide-x sm:divide-x-0 divide-white'}
              >
                <ConditionalFragment condition={activeIndex !== 0}>
                  <button
                    type={'button'}
                    className={classNames(
                      'sm:absolute sm:top-1/2 sm:-left-7 md:left-16',
                      'sm:transform sm:-translate-y-1/2',
                      'flex items-center justify-center py-4 flex-1',
                      'sm:w-14 sm:h-14',
                      'sm:bg-white sm:text-black'
                    )}
                    onClick={() => swiper?.slidePrev()}
                  >
                    <HiOutlineChevronLeft size={24} />
                  </button>
                </ConditionalFragment>
                <ConditionalFragment condition={activeIndex !== stories.length - 1}>
                  <button
                    type={'button'}
                    className={classNames(
                      'sm:absolute sm:top-1/2 sm:-right-7 md:right-16',
                      'sm:transform sm:-translate-y-1/2',
                      'flex items-center justify-center py-4 flex-1',
                      'sm:w-14 sm:h-14',
                      'sm:bg-white sm:text-black'
                    )}
                    onClick={() => swiper?.slideNext()}
                  >
                    <HiOutlineChevronRight size={24} />
                  </button>
                </ConditionalFragment>
              </div>
            </div>
          </div>
          {/*<Link href={'/agent'} passHref>*/}
          {/*  <a className={'block mx-auto py-2 px-8 w-fit leading-6 bg-white text-black'}>認識顧問團隊</a>*/}
          {/*</Link>*/}
        </div>
      </div>
    </div>
  );
};
